.btnwhatsapp{
  position: fixed;
  bottom: 30px;
  right:10px;
  width: 65px;
  height: 65px;
  background: #fff;
  padding: 8px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btnwhatsapp a{
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.btnwhatsapp a img{
  width: 90%;
  height: auto;
}

.text-btn-whatsapp{
  position: absolute;
  width: fit-content;
  right: 115%;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: 'Gilroy-medium';
  color: #14c7ff;
  background-color: #ffffff;
  padding: 10px 14px;
  border-radius: 10px;
  font-size: 15px;
}

@media screen and (min-width: 1000px){
  .btnwhatsapp{
    right: 35px;
    bottom: 45px;
  }
} 