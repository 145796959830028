.contenedor-video{
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  
}

.video{
  margin-top: 80px;
  margin-bottom: 50px;
  width: 85%;
  max-width: 1000px;
  height: auto;
  display: block;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
}

@media screen and (min-width: 768px) {
  .video{
    margin-top: 90px;
    margin-bottom: 80px;
  }
}

@media screen and (min-width: 998px) {
  .video{
    margin-top: 100px;
    margin-bottom: 40px;
  }
}


