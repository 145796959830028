@use '../../variables' as *;

@mixin tipografia($nombre, $url) {
  @font-face {
    font-family: $nombre;
    src: url($url);
  }
}
@include tipografia('Gilroy-Black','../../tipografia/gilroy/Gilroy-Black.ttf');
@include tipografia('Gilroy-Bold','../../tipografia/gilroy/Gilroy-Bold.ttf');
@include tipografia('Gilroy-ExtraBold','../../tipografia/gilroy/Gilroy-ExtraBold.ttf');
@include tipografia('Gilroy-Heavy','../../tipografia/gilroy/Gilroy-Heavy.ttf');
@include tipografia('Gilroy-Light','../../tipografia/gilroy/Gilroy-Light.ttf');
@include tipografia('Gilroy-Medium','../../tipografia/gilroy/Gilroy-Medium.ttf');
@include tipografia('Gilroy-Regular','../../tipografia/gilroy/Gilroy-Regular.ttf');
@include tipografia('Gilroy-SemiBold','../../tipografia/gilroy/Gilroy-SemiBold.ttf');
@include tipografia('Gilroy-Thin','../../tipografia/gilroy/Gilroy-Thin.ttf');
@include tipografia('Gilroy-UltraLight','../../tipografia/gilroy/Gilroy-UltraLight.ttf');

.Content-SessionHome{
  position: relative;
  height: auto;
}

.section-elementos{
  width: 100%;
  max-width: 1250px;
  margin: auto;
  position: relative;

  .content-logo{
    position: absolute;
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px 0px 0px;
    z-index: 2;
  }

  .content-header{
    background-image: url(../../imagenes/fondo-header.svg);
    height: 150vh;
    width: 100%;
    background-position-x: center;
    background-position-y: 0;
    background-size: cover;
    padding-top: 180px;
    padding-left: 10px;
    position: relative;
    max-height: 900px;

    .title{
      display: flex;
      flex-direction: column;
      width: 95%;
      font-size: 55px;
      font-family: Gilroy-Black;
      text-transform: uppercase;
      color: $Blanco;
      line-height: 1;

      span{
        font-size: 30px;
      }
    }

    .texto-header{
      color: $Blanco;
      font-family: Gilroy-Regular;
      line-height: 1.5;
      margin-top: 25px;
      width: 95%;
      font-size: 15px;
    }

    .btn-header{
      display: inline-block;
      padding: 17px;
      background-color: $AzulCeleste;
      border: none;
      border-radius: 15px;
      -webkit-border-radius: 15px;
      -moz-border-radius: 15px;
      -ms-border-radius: 15px;
      -o-border-radius: 15px;
      text-decoration: none;
      font-family: Gilroy-Regular;
      color: $Blanco;
      font-size: 15px;
      margin-top: 30px;
    }   
  }

}
.content-blob-header{
  position: absolute;
  width: 100%;
  height: auto;
  max-width: 500px;
  right: 0;
  top: 50%;
  margin-top: 100px;
  transition: all 0.5s ;
  -webkit-transition: all 0.5s ;
  -moz-transition: all 0.5s ;
  -ms-transition: all 0.5s ;
  -o-transition: all 0.5s ;
  
  .blob-header{
    width: 100%;
    height: auto;
  }

  .img-astronauta{
    width: 100%;
    height: auto;
    position: absolute;
    left: -20px;
    top: 10%;
  }
}

.content-astronauta{
  position: absolute;
  top: 60%;
  transform: translateY(-10%);
  right: 0;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  -webkit-transform: translateY(-10%);
  -moz-transform: translateY(-10%);
  -ms-transform: translateY(-10%);
  -o-transform: translateY(-10%);

  img{
    width: 100%;
    height: auto;
    max-width: 400px;
  }
}

@media screen and (min-width: 365px) {
  .Content-SessionHome{
    margin-bottom: 100px;

    .section-elementos{

      .content-header{
        height: 170vh;
      }
    }
  }   
}

@media screen and (min-width: 768px) {
  .Content-SessionHome{

    .section-elementos{

      .content-header{
        padding-left: 50px;
  
        .title{
          font-size: 70px;
  
          span{
            font-size: 40px;
          }
        }
  
        .texto-header{
          font-size: 16px;
        }
  
        .btn-header{
          padding: 20px;
        }
      }
  
    }

    .content-blob-header{
      top: 40%;
    }
  }

  .content-astronauta{
    top: 55%;

    img{
      max-width: 420px;
    }
  }
}

@media screen and (min-width: 1024px) {
  .Content-SessionHome{
    .section-elementos{

      .content-logo{
        position: relative;
        padding: 20px 0px;
        justify-content: flex-start;
        padding-left: 50px;
      }
  
      .content-header{
        border-radius: 50px;
        -webkit-border-radius: 50px;
        -moz-border-radius: 50px;
        -ms-border-radius: 50px;
        -o-border-radius: 50px;
        height: 100vh;
        display: flex;
        align-items: center;
        padding-top: 0;
  
        .content-text-header{
          width: 43%;

          h2{
            font-size: 22px;
          }
  
        }
      }
    }
  
    .content-blob-header{
      top: 0%;
      margin-top: 0px;
    }
  }
}

@media screen and (min-width: 1400px) {
  .content-astronauta{
    top: 0%;
    transform: translateY(5px);

    img{
      max-width: 600px;
    }
  }
}

@media screen and (min-width: 1440px) {
  .Content-SessionHome{

    .section-elementos{
      
      .content-header{
        padding-left: 90px;
      }
    }

    .content-blob-header{
      max-width: 600px;
    }
  }
}

@media screen and (min-width: 2560px) {
  .Content-SessionHome{

    .section-elementos{
      
      .content-header{
        height: 75vh;
        display: flex;
        align-items: center;
        padding-top: 0px;
      }
    }

    .content-blob-header{
      right: 20%;
      max-width: 700px;
      margin-top: 0px;
      transform: translateX(100px);
      -webkit-transform: translateX(100px);
      -moz-transform: translateX(100px);
      -ms-transform: translateX(100px);
      -o-transform: translateX(100px);
    }
  }
}


