/* Colores empresariales */
.Content-SessionServicios {
  position: relative;
  overflow-x: hidden;
  overflow-y: hidden;
  height: 160vh;
  max-height: 1000px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 120px 10px 60px;
  text-align: center;
  max-width: 1250px;
  margin: auto;
}
.Content-SessionServicios .content-blob-servicio {
  position: absolute;
  height: 100%;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 100px;
  max-width: 800px;
}
.Content-SessionServicios .blob-fondo {
  position: absolute;
  top: 0px;
  left: 0px;
  display: block;
}
.Content-SessionServicios .content-text-servicios {
  position: relative;
  margin-bottom: -30px;
  width: 300px;
  height: 200px;
}
.Content-SessionServicios .content-text-servicios .blob-e {
  display: none;
}
.Content-SessionServicios .content-text-servicios .text-servicios {
  position: absolute;
  top: 50%;
  opacity: 0;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: auto;
  pointer-events: none;
}
.Content-SessionServicios .content-text-servicios .text-servicios .titulo-servicios {
  font-family: "Gilroy-Black";
  font-size: 35px;
  color: #fff;
  margin-bottom: 20px;
}
.Content-SessionServicios .content-text-servicios .text-servicios .texto-servicios {
  font-family: "Gilroy-Regular";
  color: #fff;
  width: 90%;
  text-align: justify;
  margin: auto;
}
.Content-SessionServicios .content-text-servicios .visible {
  opacity: 1;
  pointer-events: visible;
}
.Content-SessionServicios .content-cards-servicios {
  width: 200px;
  height: 500px;
  position: relative;
  margin-bottom: -100px;
}
.Content-SessionServicios .content-cards-servicios .card-servicio {
  position: absolute;
  height: 110px;
  width: 110px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 30px;
  margin-bottom: 30px;
  font-family: "Gilroy-Medium";
  font-size: 14px;
  color: #fff;
  z-index: 10;
}
.Content-SessionServicios .content-cards-servicios .card-servicio::after {
  content: " ";
  position: absolute;
  width: 110px;
  height: 110px;
  background: linear-gradient(183.12deg, rgba(0, 0, 0, 0.6) -58.61%, rgba(0, 0, 0, 0.2) 122.39%);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  transform: rotate(-45deg);
  left: 0px;
  top: 0px;
  z-index: -1;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
}
.Content-SessionServicios .content-cards-servicios .card-servicio:nth-child(1) {
  left: 40%;
  top: 40%;
  transform: translate(0%, -100%);
}
.Content-SessionServicios .content-cards-servicios .card-servicio:nth-child(2) {
  left: 30%;
  top: 50%;
  transform: translate(-60%, -50%);
}
.Content-SessionServicios .content-cards-servicios .card-servicio:nth-child(3) {
  left: 40%;
  top: 78%;
  transform: translate(0%, -80%);
}
.Content-SessionServicios .content-cards-servicios .card-servicio h3 {
  margin-bottom: 10px;
}
.Content-SessionServicios .content-cards-servicios .card-servicio .btn-card-servicio {
  height: 18px;
}

@media screen and (min-width: 768px) {
  .Content-SessionServicios {
    flex-direction: row;
    padding: 0;
    justify-content: space-between;
    height: 100vh;
  }
  .Content-SessionServicios .blob-fondo {
    opacity: 0;
    left: -100%;
    pointer-events: none;
  }
  .Content-SessionServicios .content-text-servicios {
    margin-bottom: 0px;
    width: 500px;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .Content-SessionServicios .content-text-servicios .blob-e {
    display: block;
    position: absolute;
    top: 50%;
    left: -10%;
    transform: translate(0px, -48%);
    height: auto;
    width: 100%;
    -webkit-transform: translate(0px, -48%);
    -moz-transform: translate(0px, -48%);
    -ms-transform: translate(0px, -48%);
    -o-transform: translate(0px, -48%);
  }
  .Content-SessionServicios .content-text-servicios .text-servicios {
    margin: 0px 20px;
  }
  .Content-SessionServicios .content-cards-servicios {
    margin-right: 50px;
    margin-bottom: 0px;
  }
  .Content-SessionServicios .content-cards-servicios .card-servicio {
    width: 150px;
    height: 150px;
    cursor: pointer;
  }
  .Content-SessionServicios .content-cards-servicios .card-servicio::after {
    width: 150px;
    height: 150px;
  }
  .Content-SessionServicios .content-cards-servicios .card-servicio:nth-child(1) {
    left: 28%;
  }
  .Content-SessionServicios .content-cards-servicios .card-servicio:nth-child(2) {
    left: 10%;
  }
  .Content-SessionServicios .content-cards-servicios .card-servicio:nth-child(3) {
    top: 83%;
    left: 30%;
  }
  .Content-SessionServicios .content-text-servicios {
    justify-content: flex-start;
    width: 300px;
  }
  .Content-SessionServicios .content-blob-servicio {
    transform: rotate(180deg) translateY(57%);
    padding: 0;
    -webkit-transform: rotate(180deg) translateY(57%);
    -moz-transform: rotate(180deg) translateY(57%);
    -ms-transform: rotate(180deg) translateY(57%);
    -o-transform: rotate(180deg) translateY(57%);
    width: 60%;
  }
}
@media screen and (min-width: 1300px) {
  .Content-SessionServicios .content-blob-servicio {
    width: 50%;
  }
  .Content-SessionServicios .content-text-servicios {
    left: 5%;
    transform: translateX(-10px);
  }
  .Content-SessionServicios .content-cards-servicios {
    margin-right: 150px;
  }
}/*# sourceMappingURL=sessionServicios.css.map */