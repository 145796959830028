/* Colores empresariales */
.Content-SessionNostostros {
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(../../imagenes/fondo-nosotros.svg);
  background-position-x: -300px;
  background-size: 550px;
  background-repeat: no-repeat;
  background-position-y: center;
  flex-direction: column;
}

.content-cards {
  position: relative;
  width: 80%;
  max-width: 350px;
  height: 500px;
  background-color: #123C5D;
  border-radius: 13px;
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.25);
  -webkit-border-radius: 13px;
  -moz-border-radius: 13px;
  -ms-border-radius: 13px;
  -o-border-radius: 13px;
  color: #fff;
  overflow: hidden;
}
.content-cards .content-card {
  width: 100%;
  height: 80%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding: 30px;
  transition: all 0.2s;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -ms-transition: all 0.2s;
  -o-transition: all 0.2s;
  top: 50%;
  transform: translateY(-50%);
}
.content-cards .content-card .titulo-card {
  font-family: "Gilroy-Black";
  line-height: 0.9;
  font-size: 35px;
  margin-bottom: 30px;
}
.content-cards .content-card .titulo-card span {
  display: inline-block;
  font-size: 30px;
}
.content-cards .content-card .texto-card {
  font-family: "Gilroy-Regular";
  font-size: 15px;
  line-height: 1.5;
}
.content-cards .card1 {
  left: 100%;
  opacity: 0;
  transition: all 1s;
  -webkit-transition: all 1s;
  -moz-transition: all 1s;
  -ms-transition: all 1s;
  -o-transition: all 1s;
  transition-delay: 0.5s;
}
.content-cards .card2 {
  left: 100%;
  opacity: 0;
  transition: all 1s;
}
.content-cards .card3 {
  left: 100%;
  transition-delay: 0.5s;
  opacity: 0;
  transition: all 1s;
}
.content-cards .mostrar {
  left: 0%;
  transition-delay: 0.5s;
  opacity: 1;
}
.content-cards .ocultar {
  left: 100%;
  transition-delay: 1s;
  transition: left 0.3s ease-in;
  -webkit-transition: left 0.3s ease-in;
  -moz-transition: left 0.3s ease-in;
  -ms-transition: left 0.3s ease-in;
  -o-transition: left 0.3s ease-in;
}

.content-controles {
  width: 100%;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0px;
}
.content-controles .barra {
  width: 30px;
  height: 5px;
  background: #14c7ff;
  margin: 0px 10px;
  border-radius: 10px;
  opacity: 0.3;
}
.content-controles .activa {
  opacity: 1;
  transition-delay: 0.8s;
}

@media screen and (min-width: 768px) {
  .Content-SessionNostostros {
    background-position: center;
  }
}
@media screen and (min-width: 1024px) {
  .Content-SessionNostostros {
    height: 130vh;
  }
  .content-cards {
    width: 95%;
    max-width: 1100px;
    background-color: transparent;
    box-shadow: 0px 0px 0px rgb(0, 0, 0);
    display: flex;
    justify-content: space-around;
    align-items: center;
    overflow: visible;
    background-size: 560px;
    height: 500px;
  }
  .content-cards .content-card {
    position: relative;
    background-color: #123C5D;
    height: 100%;
    border-radius: 18px;
    left: 0;
    margin: 0px 20px;
    -webkit-border-radius: 18px;
    -moz-border-radius: 18px;
    -ms-border-radius: 18px;
    -o-border-radius: 18px;
    box-shadow: 9px 9px 11px rgba(0, 0, 0, 0.25);
    -webkit-backdrop-filter: blur(86px);
            backdrop-filter: blur(86px);
    opacity: 1;
  }
  .card1 {
    opacity: 1;
    transition-delay: 0s;
  }
  .card2 {
    opacity: 1;
    transition-delay: 0s;
  }
  .card3 {
    opacity: 1;
    transition-delay: 0s;
  }
  .content-controles {
    display: none;
  }
}/*# sourceMappingURL=sessionNosotros.css.map */