/* Colores empresariales */
.Content-footer {
  position: relative;
  width: 100%;
  height: 500px;
  overflow: hidden;
  margin-top: 80px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.Content-footer .content-blob-footer {
  position: absolute;
  width: 100%;
  height: 700px;
  left: 50%;
  bottom: 0%;
  transform: rotate(80deg) translate(-100px, 50px) scale(1, 2);
}
.Content-footer .content-info-footer {
  z-index: 2;
  position: relative;
}
.Content-footer .content-info-footer .content-logo-footer {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Content-footer .content-info-footer .content-logos-footer {
  width: 100%;
  height: auto;
  margin: 50px auto 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 350px;
}
.Content-footer .content-info-footer .content-logos-footer img {
  width: 85%;
}
.Content-footer .content-info-footer .derechos-sitio {
  font-family: "Gilroy-Medium";
  font-size: 14px;
  text-align: center;
  width: 80%;
  margin: 20px auto;
  color: #fff;
  line-height: 20px;
}

@media screen and (min-width: 768px) {
  .Content-footer .content-blob-footer {
    transform: rotate(90deg) translate(220px, 30%) scale(1.3);
  }
  .Content-footer .content-info-footer .content-logo-footer img {
    width: 35%;
  }
  .Content-footer .content-info-footer .derechos-sitio {
    width: 100%;
    margin-bottom: 40px;
  }
}
@media screen and (min-width: 1024px) {
  .Content-footer .content-blob-footer {
    position: absolute;
    width: 100%;
    height: 700px;
    left: 20%;
    bottom: -40%;
    transform: rotate(80deg) translate(220px, 30%) scale(2);
    -webkit-transform: rotate(80deg) translate(220px, 30%) scale(2);
    -moz-transform: rotate(80deg) translate(220px, 30%) scale(2);
    -ms-transform: rotate(80deg) translate(220px, 30%) scale(2);
    -o-transform: rotate(80deg) translate(220px, 30%) scale(2);
  }
}
@media screen and (min-width: 1024px) {
  .Content-footer .content-blob-footer {
    position: absolute;
    width: 100%;
    height: 700px;
    left: 20%;
    bottom: -140%;
    transform: rotate(90deg) translate(220px, 30%) scale(2);
    -webkit-transform: rotate(90deg) translate(220px, 30%) scale(2);
    -moz-transform: rotate(90deg) translate(220px, 30%) scale(2);
    -ms-transform: rotate(90deg) translate(220px, 30%) scale(2);
    -o-transform: rotate(90deg) translate(220px, 30%) scale(2);
  }
}/*# sourceMappingURL=footer.css.map */