/* Colores empresariales */
.btn-menu {
  position: absolute;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background: rgba(20, 199, 255, 0.35);
  z-index: 15;
  cursor: pointer;
}

.btn-menu img {
  width: 22px;
}

.btn-movil {
  top: 7%;
  right: 25px;
  transform: translateY(0px);
}

.Content-Menu {
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  width: 100%;
  height: 100vh;
  min-width: 320px;
  top: 0;
  right: -100%;
  z-index: 20;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 0.5s ease-in-out;
}
.Content-Menu .btn-t-e {
  display: none;
}
.Content-Menu .btn-cerrar {
  position: absolute;
  background: rgba(225, 225, 225, 0.5);
  right: 20px;
  top: 20px;
  padding: 10px 15px;
  font-family: "Gilroy-Black";
  font-size: 20px;
  color: #fff;
  cursor: pointer;
  border-radius: 50px;
}
.Content-Menu .ant-anchor-fixed .ant-anchor-link a {
  text-decoration: none;
  font-family: "Gilroy-Medium";
  margin: 20px 0px;
  display: inline-block;
  color: #fff;
  font-size: 20px;
  opacity: 0;
  transition: transform 1s;
}
.Content-Menu .ant-anchor-fixed .ant-anchor-link a:hover {
  color: #14c7ff;
  transform: scale(1.2);
}

.Content-Menu.mostrar {
  right: 0%;
  transition: right 1s;
  transition-delay: right 2s;
}
.Content-Menu.mostrar .ant-anchor-fixed .ant-anchor-link:nth-child(2) a {
  transition-delay: all 1s;
  transition: opacity 1s;
  opacity: 1;
}
.Content-Menu.mostrar .ant-anchor-fixed .ant-anchor-link:nth-child(3) a {
  transition-delay: all 1.8s;
  transition: opacity 1s;
  opacity: 1;
}
.Content-Menu.mostrar .ant-anchor-fixed .ant-anchor-link:nth-child(4) a {
  transition-delay: all 2.1s;
  transition: opacity 1s;
  opacity: 1;
}
.Content-Menu.mostrar .ant-anchor-fixed .ant-anchor-link:nth-child(5) a {
  transition-delay: all 2.4s;
  transition: opacity 1s;
  opacity: 1;
}

@media screen and (min-width: 768px) {
  .Content-Menu .ant-anchor-fixed .ant-anchor-link a {
    font-size: 23px;
  }
}
@media screen and (min-width: 1024px) {
  .btn-movil {
    position: fixed;
    top: 5vh;
    right: 20px;
    transform: translateY(0px);
  }
}/*# sourceMappingURL=menu.css.map */