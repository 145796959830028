@use './../../variables' as *;

.container_modal{
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: #0000007f;
  z-index: 50;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.5s; 
}

.container_modal.mostrar{
  opacity: 1;
  pointer-events: painted;
}

.modal{
  width: 90%;
  max-width: 500px;
  height: 80%;
  overflow: hidden;
  border-radius: 10px;
  background-image: url("../../imagenes/fondo_modal_movil.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px 20px;
  transition: all 0.3s ease;
}

.modal_icon_close{
  position: absolute;
  right: 15px;
  top: 15px;
  width: 20px;
  height: auto;
  cursor: pointer;
}

.modal_titulo{
  font-family: 'Gilroy-bold';
  color: $Blanco;
  font-size: 32px;
  line-height: 70px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.modal_texto{
  text-align: center;
  font-family: 'Gilroy-regular';
  color: $Blanco;
  line-height: 25px;
  display: block;
  width: 90%;
  margin-bottom: 30px;
}

.btn-modal{
  display: flex;
  padding: 12px 25px;
  border-radius: 50px;
  background-color: $Blanco;
  text-decoration: none;
  column-gap: 10px;

  .icono-btn-modal{
    width: 18px;
  }

  p{
    font-family: 'Gilroy-medium';
    font-size: 15px;
    color: $AzulCeleste;
  }
}

.circulo_superior,
.circulo_inferior{
  position: absolute;
  opacity: 0.6;
}


.circulo_superior{
  width: 120px;
  top: 0;
  left: 0;
}

.circulo_inferior{
  width: 80px;
  bottom: 0;
  right: 0;
}

@media screen and (min-width:768px) {
  .modal{
    height: 60vh;
    max-width: 600px;
  }
}

@media screen and (min-width:998px) {
  .modal{
    height: 65vh;
    max-width: 800px;
    background-image: url('../../imagenes/fondo_modal_escritorio_1.png');
  }

  .modal_icon_close{
    right: 25px;
    top: 25px;
  }

  .modal_titulo{
    font-size: 50px;
    margin-top: 0px;
  }

  .modal_texto{
    max-width: 500px;
    font-size: 16px;
    margin: 15px 0px 30px;
  }

  .circulo_superior{
    width: 180px;
  }

  .circulo_inferior{
    width: 130px;
  }
}