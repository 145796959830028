/* Colores empresariales */
$Naranjado: #ff8c19;
$Amarillo: #ffbf01;
$VerdeMenta: #00face;
$AzulCeleste: #14c7ff;
$AzulRey: #376eff;
$AzulOscuro: #0e0a3d;
$AzulClaro: #448bca;
$AzulGris: #789bb9;

$Blanco: #fff;
$Negro: #000;