@use '../../variables.scss' as *;

.Content-SessionContacto.section {
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  height: 180vh;
  max-height: 1100px;
  margin: auto;

  .content-title {
    width: 100%;
    text-align: center;
    margin: 10px 0px 40px;  

    .titlo-contacto {
      font-family: 'Gilroy-Black';
      font-size: 35px;
      color: $Blanco;
    }
  }

  .content-form {
    width: 100%;
    background: linear-gradient(109.02deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.2) 100%);
    box-shadow: 6px 7px 26px rgb(0 0 0 / 25%);
    border-radius: 28px;
    margin: 0px  auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    border: 1px solid #000;
    height: 800px;

    .content-botones {
      width: 100%;
      border-top-left-radius: 28px;
      border-top-right-radius: 28px;
      height: 60px;
      display: flex;
      justify-content: space-around;
      align-items: center;

      .selector {
        position: absolute;
        z-index: 1;
        background-color: $AzulCeleste;
        width: 50%;
        height: 60px;
        right: 0;
        border-top-right-radius: 28px;
        border-top-left-radius: 0px;
      }

      .selector1 {
        position: absolute;
        z-index: 1;
        background-color: $AzulCeleste;
        width: 50%;
        height: 60px;
        left: 0;
        border-top-left-radius: 28px;
        border-top-right-radius: 0px;
      }

      .inactivo{
        background-color: rgba(71, 71, 71, 0.4);
      }

      p {
        height: 100%;
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $Blanco;
        font-family: 'Gilroy-Regular';
        font-size: 14px;
        position: relative;
        z-index: 2;
        cursor: pointer;
      }

      p:nth-child(1) {
        border-top-left-radius: 28px;
      }

      p:nth-child(2) {
        border-top-right-radius: 28px;
      }
    } 

    .content-formularios{
      height: 650px;
      overflow: hidden;
      position: relative;
      width: 100%;
      max-width: 320px;
    }

    .form{
      position: absolute;
      top: 0;
      left: 100%;
      width: 90%;

      .titulo-form{
        width: 100%;
        text-align: center;
        padding: 20px 0px;
        font-family: 'Gilroy-Black';
        color: $AzulCeleste;
        margin-top: 20px;
      }  

      .form-text {
        color: #fff;
        width: 90%;
        margin: auto;
        text-align: center;
        font-family: 'Gilroy-Regular';
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 20px;
      }

      .input {
        width: 85%;
        height: 44px;
        border: 1px solid $AzulCeleste;
        display: block;
        margin: 0px auto 15px;
        background: #113E61;
        box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.25);
        border-radius: 8px;
        color: $Blanco;
        padding-left: 10px;
        font-family: 'Gilroy-Regular';
        outline: none;
      }

      .input.Error{
        border: 1px solid red;
      }

      .input.Verificado{
        border: 1px solid green;
      }

      .area {
        padding-top: 12px;
        height: 100px;
      } 

      .perfil{
        display: none;
      }

      input.btn-form {
        padding: 15px 50px;
        margin: auto;
        display: block;
        background: $AzulCeleste;
        box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.25);
        border-radius: 8px;
        border: none;
        font-family: 'Gilroy-Medium';
        color: $Blanco;
        cursor: pointer;
      }
    }

    .form-active{
      left: 50%;
      transform: translateX(-50%);
      transition-delay: 0.5s;
      transition: all 1s;
      -webkit-transition: all 1s;
      -moz-transition: all 1s;
      -ms-transition: all 1s;
      -o-transition: all 1s;
    }

    .content-info-form{
      width: 80%;
      height: 350px;
      background: $AzulCeleste;
      box-shadow: -8px 7px 21px rgba(0, 0, 0, 0.1);
      border-radius: 28px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      position: absolute;
      bottom: -22%;
      transform: translateY(0%);

      h3{
        display: none;
        text-align: center;
        margin: 20px 0px;
        font-family: 'Gilroy-Black';
        color: $Blanco;
        font-size: 26px;
      }

      .enlace-form {
        display: block;
        width: 100%;
        height: auto;
        margin: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom: 5px;
        font-family: 'Gilroy-Regular';
        text-decoration: none;
        color: $Blanco;
        text-align: center;
      }

      .img-maps{
        width: 100%;
        height: auto
      }
    }

    .content-info-juridica{
      bottom: -33%;
      transition: all 0.3s;
      -webkit-transition: all 0.3s;
      -moz-transition: all 0.3s;
      -ms-transition: all 0.3s;
      -o-transition: all 0.3s;
    }
  }
}

img.iconos {
  width: 50px;
  height: auto;
}

.content-redes-sociales {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

h2.title-alert {
  font-family: 'Gilroy-Black';
  font-size: 30px;
  margin-bottom: 10px;
}

p.text-alert {
  font-size: 15px;
  font-family: 'Gilroy-Regular';
  line-height: 22px;
}


@media screen and (min-width: 768px) {
  .Content-SessionContacto.section {
    height: 220vh;

    .content-title{
      margin-bottom: 50px;

      .titlo-contacto{
        font-size: 39px;
      }
    }

    .content-form {
      width: 80%;
      max-width: 550px;
      height: 800px;

      .content-formularios {
        max-width: 500px;
        margin: auto;
      } 

      .form{
        width: 380px;
        padding-bottom: 20px;

        .titulo-form{
          font-size: 30px;
        }

        .form-text{
          margin: 0px auto 30px;
        }

        input.btn-form{
          margin-top: 20px;
        }
      }

      .form-active{
        left: 50%;
        transform: translateX(-50%);
        -webkit-transform: translateX(-50%);
        -moz-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        -o-transform: translateX(-50%);
      }

      .content-info-form{
        max-width: 380px;
        padding-top: 30px;
        padding-left: 10px;
      }

      .content-info-juridica{
        bottom: -38%;
      }
    }
  }

  .Content-alert-from {
    display: flex;
    flex-direction: row-reverse;
  }
}

@media screen and (min-width: 1024px) {
  .Content-SessionContacto.section{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 120vh;

    .content-title{
      display: none;
    }

    .content-form{
      width: 80%;
      max-width: 900px;
      flex-direction: row-reverse;
      align-items: center;
      justify-content:flex-start;
      height: 670px;

      .content-botones{
        width: 60px;
        border-top-left-radius: 0px;
        border-top-right-radius: 28px;
        border-bottom-right-radius: 28px;
        height: 100%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        position: relative;

        .selector{
          width: 60px;
          height: 50%;
          right: 0;
          top: 0;
          border-top-right-radius: 28px;
          border-top-left-radius: 0px;
        }

        .selector1{
          width: 60px;
          height: 50%;
          left: 100%;
          transform: translateX(-60px);
          bottom: 0;
          border-top-left-radius: 0px;
          border-bottom-right-radius: 28px;
        }

        p{
          position: absolute;
          width: 335px;
          height: 60px;
          transform: rotate(90deg);
        }

        p:nth-child(1) {
          border-top-right-radius: 28px;
          border-top-left-radius: 0px;
          bottom: 136px;
        }
  
        p:nth-child(2) {
          border-top-left-radius: 28px;
          border-top-right-radius: 0px;
          top: 136px;
        }
      }

      .content-formularios{
        margin: 0px;

        .form{
          .titulo-form{
            font-size: 36px;
          }
        }

        .form-persona {
          .titulo-form{
            margin-top: 50px;
          }
        }
      }

      .content-info-form{
        max-width: 300px;
        padding-top: 30px;
        padding-left: 10px;
        bottom: 50%;
        left: -10%;
        transform: translate(0%, 50%);
        height: auto;

        h3{
          display: block;
          font-size: 36px;
        }
      }

      .content-info-juridica{
        bottom: 50%;
        transition: all 0.3s;
        -webkit-transition: all 0.3s;
        -moz-transition: all 0.3s;
        -ms-transition: all 0.3s;
        -o-transition: all 0.3s;
      }
    }
  }

  .swal2-container.swal2-bottom-start.container-pop.swal2-backdrop-show {
    padding: 42px;
  }
}

@media screen and (min-width: 1300px) {
  .Content-SessionContacto.section{

    .content-form{
      width: 90%;
      max-width: 1000px;

      .content-formularios{
        max-width: 700px;

        .form{
          .input{
            width: 100%;
            margin: 0px auto 20px;
          }
        }

        .form-juridica{
          .titulo-form{
            padding-top: 0px;
          }
        }
      }

      .content-info-form{
        max-width: 350px;
        height: 400px;
        left: -15%;
      }
    }
  }
}