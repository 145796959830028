@use 'variables.scss' as *;

*,
::after,
::before{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html{
  scroll-behavior: smooth;
}

.contenedor-principal{
  background-image: url(./imagenes/fondo.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: auto;
  min-width: 325px;
}